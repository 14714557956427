<section>
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/concurso/inscricao-aberta" title="{{'lista_inscricao_shared.abertas' | translate}}">{{'lista_inscricao_shared.abertas' | translate}}</a>
        </li>
        <li class="breadcrumb-item">
          <a routerLink="/concurso/inscricao-andamento" title="{{'lista_inscricao_shared.andamento' | translate}}">{{'lista_inscricao_shared.andamento' | translate}}</a>
        </li>
        <li class="breadcrumb-item active">{{'lista_inscricao_shared.proximos' | translate}}</li>
        <li class="breadcrumb-item"><a routerLink="/concurso/inscricao-encerrada" title="{{'lista_inscricao_shared.encerrados' | translate}}">{{'lista_inscricao_shared.encerrados' | translate}}</a></li>
      </ol>
    </nav>
    <div class="alert alert-success small">
      <h2 class="mb-0 mt-0" style="font-size: 18px">{{'lista_inscricao_proxima.proximos_concursos' | translate}}</h2>
    </div>
    <div class="input-group btn-search" style="margin-bottom: 20px">
      <input
        (keydown.enter)="search()"
        [(ngModel)]="input"
        type="search"
        class="form-control"
        placeholder="{{'lista_inscricao_shared.pesquisar' | translate}}"
        name="filtro" />
      <button (click)="search()" class="btn btn-secondary" type="button">
        <i class="bi bi-search" aria-hidden="true">&nbsp;</i>
      </button>
    </div>
    <div
      class="bg-light border rounded-3 mb-3"
      itemscope=""
      itemtype="http://schema.org/Event"
      *ngFor="let concurso of concursos">
      <a
        routerLink="/concurso/{{ concurso.id }}"
        style="color: inherit; text-decoration: none"
        title="{{ concurso.titulo }}">
        <div class="p-3">
          <div itemprop="location" itemscope="" itemtype="http://schema.org/Place">
            <p itemprop="name" style="font-size: 1rem">{{ concurso.campus }}</p>
            <div style="display: none" itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
              <span itemprop="addressRegion">SP</span>
            </div>
          </div>
          <h3 class="text-primary" itemprop="name" style="font-size: 1rem">{{ concurso.titulo }}</h3>
          <h4 style="font-size: 1rem; font-weight: normal">{{ concurso.descricao }}</h4>
          <h5
            itemprop="startDate"
            attr.content="{{ concurso.inicio }}T00:00"
            style="font-size: 1rem; font-weight: bold">
            {{'lista_inscricao_shared.inscricoes_de' | translate}} {{ concurso.inicio | date : 'dd/MM/yyyy' }} {{ concurso.horaInicio.substring(0, 5) }} h {{'shared.ate' | translate}} {{ concurso.fim | date : 'dd/MM/yyyy' }} {{'shared.as' | translate}}
            {{ concurso.horaFim.substring(0, 5) }} h
          </h5>
          <h4 [hidden]="concurso.resumo === null" style="font-size: 1rem; font-weight: normal">
            <b>Abstract:&nbsp;</b><br />
            {{ concurso.resumo }}
          </h4>
        </div>
      </a>
    </div>
    <app-pagination
      [current]="current"
      [totalPages]="totalPages"
      [totalElements]="totalElements"
      (goTo)="onGoTo($event)"
      (next)="onNext($event)"
      (previous)="onPrevious($event)">
    </app-pagination>
  </div>
</section>
