<section *ngIf="inscricaoFicha?.id">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <a routerLink="/candidato/inscricoes" title="{{'shared.area_do_candidato' | translate}}" class="link">{{'shared.area_do_candidato' | translate}}</a>
        </li>
        <li class="breadcrumb-item active">{{'shared.inscricao' | translate}}</li>
      </ol>
    </nav>

    <div class="print-header"></div>
    
    <app-formulario-situacao [idInscricao]="id" [events]="eventsSubject.asObservable()"></app-formulario-situacao>

    <form [formGroup]="form">
      <app-candidato-fieldset-inscricao [inscricaoFicha]="inscricaoFicha"></app-candidato-fieldset-inscricao>

      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'ficha_inscricao_shared.dados_candidato' | translate}}</legend>
        <div class="row">
          <div class="col-sm-8" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.nome' | translate}}</span>
              <input (blur)="save('nome')" class="form-control" formControlName="nome" type="text" maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.data_nascimento' | translate}}</span>
              <input
                (blur)="save('dataNascimento')"
                class="form-control datepicker"
                mask="00/00/0000"
                [dropSpecialCharacters]="false"
                formControlName="dataNascimento"
                type="text"
                maxlength="10" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.nome_social' | translate}}</span>
              <input
                (blur)="save('nomeSocial')"
                class="form-control"
                formControlName="nomeSocial"
                type="text"
                maxlength="80" />
              <span class="input-group-text" *ngIf="permiteEdicao">
                <input
                  id="nSocial"
                  (change)="alteraNomeSocial()"
                  title="{{'ficha_inscricao_shared.utilizo_nome_social' | translate}}"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomeSocial" />
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.nome_mae' | translate}}</span>
              <input
                (blur)="save('nomeMae')"
                class="form-control"
                formControlName="nomeMae"
                type="text"
                maxlength="80" />
              <span class="input-group-text" *ngIf="permiteEdicao">
                <input
                  (change)="alteraMaeDesconhecida()"
                  id="maeDesc"
                  title="{{'ficha_inscricao_shared.desconhecida' | translate}}"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomeMae" />
              </span>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.nome_pai' | translate}}</span>
              <input
                (blur)="save('nomePai')"
                class="form-control"
                formControlName="nomePai"
                type="text"
                maxlength="80" />
              <span class="input-group-text" *ngIf="permiteEdicao">
                <input
                  (change)="alteraPaiDesconhecido()"
                  id="paiDesc"
                  title="{{'ficha_inscricao_shared.desconhecido' | translate}}"
                  type="checkbox"
                  class="form-check-input"
                  [checked]="nomePai" />
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.profissao' | translate}}</span>
              <input
                (blur)="save('profissao')"
                class="form-control"
                formControlName="profissao"
                type="text"
                maxlength="120" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-5" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.grau_escolaridade' | translate}}</span>
              <select class="form-control" (blur)="save('escolaridade')" formControlName="escolaridade">
                <option value=""></option>
                <option *ngFor="let type of grauEscolaridade | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.sexo' | translate}}</span>
              <select class="form-control" (blur)="save('sexo')" formControlName="sexo">
                <option value=""></option>
                <option *ngFor="let type of sexo | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.estado_civil' | translate}}</span>
              <select class="form-control" (blur)="save('estadoCivil')" formControlName="estadoCivil">
                <option value=""></option>
                <option *ngFor="let type of estadoCivil | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.estrangeiro' | translate}}</span>
              <select
                class="form-control"
                (blur)="save('estrangeiro')"
                (change)="validaEstrangeiro()"
                formControlName="estrangeiro">
                <option value=""></option>
                <option *ngFor="let type of estrangeiro | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">CPF</span>
              <input
                (blur)="save('cpf')"
                class="form-control"
                mask="000.000.000-00"
                [dropSpecialCharacters]="false"
                formControlName="cpf"
                type="text"
                maxlength="14" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.passaporte' | translate}}</span>
              <input
                (blur)="save('passaporte')"
                class="form-control passaporte"
                formControlName="passaporte"
                type="text" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">RNE</span>
              <input
                (blur)="save('rne')"
                class="form-control"
                mask="AAAAAAA-A"
                [dropSpecialCharacters]="false"
                formControlName="rne"
                type="text"
                maxlength="9" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">RG</span>
              <input
                (blur)="save('rg')"
                mask="A{13}"
                class="form-control"
                formControlName="rg"
                type="text"
                maxlength="13" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.uf' | translate}}</span>
              <select class="form-control" (blur)="save('uf')" formControlName="uf">
                <option value=""></option>
                <option *ngFor="let type of estado | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.data_expedicao' | translate}}</span>
              <input
                (blur)="save('dataExpedicao')"
                class="datepicker form-control"
                mask="00/00/0000"
                [dropSpecialCharacters]="false"
                formControlName="dataExpedicao"
                type="text"
                maxlength="10" />
            </div>
          </div>
          <div class="col-sm-3" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.orgao' | translate}}</span>
              <select class="form-control" (blur)="save('orgaoExpedicao')" formControlName="orgaoExpedicao">
                <option value=""></option>
                <option *ngFor="let type of orgaoExpedicaoRg | keyvalue" [value]="type.key">
                  {{ type.value }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'ficha_inscricao_shared.local_nascimento' | translate}}</legend>
        <div id="localNascimento">
          <div class="row">
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">{{'ficha_inscricao_shared.pais' | translate}}</span>
                <select
                  class="form-control"
                  (blur)="save('pais')"
                  formControlName="pais"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option value="Brasil">Brasil</option>
                </select>
                <input
                  (blur)="save('pais')"
                  class="form-control"
                  formControlName="pais"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">{{'ficha_inscricao_shared.estado' | translate}}</span>
                <select
                  class="form-control"
                  (blur)="save('estado')"
                  (change)="selecionaEstado()"
                  formControlName="estado"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option *ngFor="let type of estado | keyvalue" [value]="type.key">
                    {{ type.value }}
                  </option>
                </select>
                <input
                  (blur)="save('estado')"
                  class="form-control"
                  id="estado"
                  formControlName="estado"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
            <div class="col-sm-4" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text">{{'ficha_inscricao_shared.cidade' | translate}}</span>
                <select
                  class="form-control"
                  (blur)="save('cidade')"
                  formControlName="cidade"
                  *ngIf="form.controls['estrangeiro'].value === 'NAO'">
                  <option value=""></option>
                  <option
                    *ngFor="let cidade of cidades"
                    [attr.selected]="cidade === form.controls['cidade'].value ? true : null">
                    {{ cidade }}
                  </option>
                </select>
                <input
                  (blur)="save('cidade')"
                  class="form-control"
                  formControlName="cidade"
                  type="text"
                  maxlength="80"
                  *ngIf="form.controls['estrangeiro'].value !== 'NAO'" />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">{{'ficha_inscricao_shared.informacoes_contato' | translate}}</legend>
        <div class="row">
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Email</span>
              <input
                (blur)="save('email')"
                class="form-control email"
                formControlName="email"
                type="email"
                [email]="true"
                maxlength="50" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.celular' | translate}}</span>
              <input
                (blur)="save('celular')"
                class="form-control celular"
                mask="(00) 00000-0000"
                [dropSpecialCharacters]="false"
                formControlName="celular"
                type="text"
                maxlength="16" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.telefone' | translate}}</span>
              <input
                (blur)="save('telefone')"
                class="form-control telefone"
                mask="(00) 0000-0000"
                [dropSpecialCharacters]="false"
                formControlName="telefone"
                type="text"
                maxlength="14" />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <!--<div class="form-control">
          <span class="input-group-text">Endereço no Brasil?</span>
          <select
            class="form-control"
            style="margin-top: 0.5%"
            (change)="alteraTipoEndereco()"
            formControlName="enderecoNacional"
            required>
            <option value="true">Sim</option>
            <option value="false">Não</option>
          </select>
        </div>-->

        <legend class="w-auto">{{'ficha_inscricao_shared.endereco' | translate}}</legend>
        <div class="row">
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.cep' | translate}}</span>
              <input
                class="form-control"
                (blur)="save('enderecoCep')"
                formControlName="enderecoCep"
                type="text"
                maxlength="10" />
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.logradouro' | translate}}</span>
              <input
                (ch)="save('enderecoLogradouro')"
                class="form-control"
                formControlName="enderecoLogradouro"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-2" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">Nº</span>
              <input
                (blur)="save('enderecoNumero')"
                class="form-control"
                formControlName="enderecoNumero"
                type="text"
                maxlength="80" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.bairro' | translate}}</span>
              <input
                (blur)="save('enderecoBairro')"
                class="form-control"
                formControlName="enderecoBairro"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.cidade' | translate}}</span>
              <input
                (blur)="save('enderecoCidade')"
                class="form-control"
                formControlName="enderecoCidade"
                type="text"
                maxlength="80" />
            </div>
          </div>
          <div class="col-sm-4" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.estado' | translate}}</span>
              <input
                (blur)="save('enderecoEstado')"
                class="form-control"
                formControlName="enderecoEstado"
                type="text"
                maxlength="2"
                maxlength="80" />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.complemento' | translate}}</span>
              <input
                (blur)="save('enderecoComplemento')"
                class="form-control"
                formControlName="enderecoComplemento"
                type="text"
                maxlength="80" />
            </div>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2">
        <legend class="w-auto">Outros</legend>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.canhoto' | translate}}</span>
              <select class="form-control" (blur)="save('canhoto')" formControlName="canhoto">
                <option value=""></option>
                <option value="true">{{'shared.sim' | translate}}</option>
                <option value="false">{{'shared.nao' | translate}}</option>
              </select>
            </div>
          </div>
        </div>
        <fieldset class="border rounded-3 p-2" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
          <legend class="w-auto" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">{{'ficha_inscricao_shared.autedeclaracao' | translate}}</legend>
          <div class="row" *ngIf="tipoConcurso?.localeCompare('TECADM') === 0">
            <div class="col-sm-6" style="margin-top: 0.5%">
              <div class="input-group" style="margin-top: 0.5%">
                <span class="input-group-text" style="margin-top: 0.5%">
                  {{'ficha_inscricao_shared.declara_cotista' | translate}}?</span
                >
                <select
                  style="margin-top: 0.5%"
                  class="form-control"
                  (blur)="save('autodeclaraRaca')"
                  (change)="validaAutodeclaraRaca()"
                  formControlName="autodeclaraRaca"
                  required>
                  <option value="true">{{'shared.sim' | translate}}</option>
                  <option value="false">{{'shared.nao' | translate}}</option>
                </select>
              </div>
            </div>
          </div>

          <div
            class="col-sm-6"
            style="margin-top: 0.5%"
            *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
            <span class="input-group-text" style="margin-top: 0.5%"> {{'ficha_inscricao_shared.me_auto_declaro_pessoa' | translate}}: </span>
            <div class="input-group" style="margin-top: 0.5%">
              <select class="form-control" (blur)="save('autodeclaracao')" formControlName="autodeclaracao" required>
                <option value="PRETO">{{'ficha_inscricao_shared.preta' | translate}}</option>
                <option value="PARDO">{{'ficha_inscricao_shared.parda' | translate}}</option>
                <option value="INDIGENA">{{'ficha_inscricao_shared.indigena' | translate}}</option>
              </select>
            </div>
          </div>
          <div class="row" *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
            <div class="col-sm-6" style="margin-top: 0.5%">
              <div class="input-group">
                <span class="input-group-text"
                  >{{'ficha_inscricao_shared.deseja_utilizar_sistema_pontuacao' | translate}}</span
                >
                <select
                  class="form-control"
                  (blur)="save('pontuacaoDiferenciada')"
                  formControlName="pontuacaoDiferenciada"
                  required>
                  <option value="true" data-bs-toggle="modal" data-bs-target="#dialogConcluirModal">{{'shared.sim' | translate}}</option>
                  <option value="false">{{'shared.nao' | translate}}</option>
                </select>
              </div>
              <!-- Modal -->
              <div
                class="modal fade"
                id="dialogConcluirModal"
                tabindex="-1"
                aria-labelledby="dialogConcluirModalLabel"
                aria-hidden="true"
                data-bs-backdrop="static">
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h1 class="modal-title fs-5" id="dialogConcluirModalLabel">{{'shared.atencao' | translate}}</h1>
                    </div>
                    <div class="modal-body">
                      {{'ficha_inscricao_shared.efetivar_participacao_cotista' | translate}}
                    </div>
                    <div class="modal-footer">
                      <button type="button" class="btn btn-primary" data-bs-dismiss="modal">{{'ficha_inscricao_shared.estou_ciente' | translate}}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-sm-12"
            style="margin-top: 0.5%"
            *ngIf="form.controls.autodeclaraRaca.value && tipoConcurso?.localeCompare('TECADM') === 0">
            <div class="input-group">
              <span class="input-group-text">
                <input
                  type="checkbox"
                  class="form-check-input"
                  formControlName="declaracaoVeracidade"
                  [required]="true" />
              </span>
              <p class="form-control" style="height: 100%">
                {{'ficha_inscricao_shared.declaracao_veracidade' | translate}}
              </p>
            </div>
          </div>
        </fieldset>
        <div class="row">
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group">
              <span class="input-group-text">{{'ficha_inscricao_shared.possui_deficiencia' | translate}}</span>
              <select
                class="form-control"
                (blur)="save('possuiDeficiencia')"
                (change)="validaDeficiencia()"
                formControlName="possuiDeficiencia">
                <option value=""></option>
                <option value="true">{{'shared.sim' | translate}}</option>
                <option value="false">{{'shared.nao' | translate}}</option>
              </select>
            </div>
          </div>
          <div class="col-sm-6" style="margin-top: 0.5%">
            <div class="input-group" *ngIf="form.controls.possuiDeficiencia.value === 'true'">
              <ng-select [multiple]="true" formControlName="deficiencia" class="custom" style="width: 100%">
                <ng-option
                  *ngFor="let type of deficiencia | keyvalue"
                  [value]="type.key"
                  [attr.selected]="form.controls['deficiencia'].value.toString().includes(type.value) ? true : null"
                  >{{ type.value }}</ng-option
                >
              </ng-select>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="form.controls.possuiDeficiencia.value === 'true'">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <hr class="mt-2 mb-2" />
            <p class="fw-bold">{{'ficha_inscricao_shared.recursos_especiais' | translate}}</p>
            <p style="white-space: pre-line">{{ inscricaoFicha?.deficienciaNecessidadesProva }}</p>
            <p class="text-danger small">
              <i class="bi bi-exclamation-circle" aria-hidden="true">&nbsp;</i> {{'ficha_inscricao_shared.atendimento_sujeito_analise' | translate}}
            </p>
          </div>
        </div>
        <div
          class="row no-print"
          *ngIf="
            inscricaoFicha?.deficienciaLaudoMedico ||
            inscricaoFicha?.reducaoComprovanteEstudante ||
            inscricaoFicha?.reducaoComprovanteRenda ||
            inscricaoFicha?.documentoNomeSocial
          ">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <hr class="mt-0 mb-2" />
            <a
              (click)="docNomeSocial()"
              class="btn btn-warning btn-sm"
              *ngIf="inscricaoFicha?.documentoNomeSocial">
              <i class="bi bi-eye" style="margin-right: 5px">&nbsp;</i>{{'candidato_inscricao_ficha.visualizar_doc_nome_social' | translate}}
            </a>
            <a
              (click)="deficienciaLaudoMedico()"
              class="btn btn-warning btn-sm me-3"
              *ngIf="inscricaoFicha?.deficienciaLaudoMedico">
              <i class="bi bi-eye" style="margin-right: 5px">&nbsp;</i>{{'candidato_inscricao_ficha.visualizar_laudo_medico' | translate}}
            </a>
            <a
              (click)="comprovanteEstudante()"
              class="btn btn-warning btn-sm me-3"
              *ngIf="inscricaoFicha?.reducaoComprovanteEstudante">
              <i class="bi bi-eye" style="margin-right: 5px">&nbsp;</i>{{'candidato_inscricao_ficha.visualizar_comprovante_estudante' | translate}}
            </a>
            <a
              (click)="comprovanteRenda()"
              class="btn btn-warning btn-sm"
              *ngIf="inscricaoFicha?.reducaoComprovanteRenda">
              <i class="bi bi-eye" style="margin-right: 5px">&nbsp;</i>{{'candidato_inscricao_ficha.visualizar_comprovante_renda' | translate}}
            </a>
          </div>
        </div>
      </fieldset>
      <fieldset class="border rounded-3 p-2" *ngIf="temas.length">
        <legend class="w-auto">{{'ficha_inscricao_shared.pontos' | translate}}</legend>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 0.5%">
            <div class="input-group">
              <ng-select [multiple]="true" formControlName="temas" class="custom" style="width: 100%">
                <ng-option
                  *ngFor="let value of temas"
                  [value]="value"
                  [attr.selected]="form.controls['temas'].value.toString().includes(value) ? true : null"
                  >{{ value }}</ng-option
                >
              </ng-select>
            </div>
          </div>
        </div>
      </fieldset>
    </form>
    <div class="no-print">
      <div class="row mt-3">
        <div class="col-12">
          <div class="alert alert-warning">
            {{'ficha_inscricao_shared.caso_duvidas' | translate}}
            <a routerLink="/candidato/contato/{{ inscricaoFicha?.idConcurso }}">{{'ficha_inscricao_shared.clique_aqui' | translate}}</a>.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <a routerLink="/candidato/inscricoes" class="btn btn-secondary btn-sm"
            ><i class="bi bi-arrow-left-short" aria-hidden="true">&nbsp;</i> <span>{{'shared.voltar' | translate}}</span></a
          >
        </div>
        <div class="col-6 d-flex flex-row-reverse">
          <button (click)="gravar()" *ngIf="permiteEdicao" class="btn btn-primary btn-sm ms-2">
            <i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i> {{'shared.gravar' | translate}}
          </button>
          <button (click)="print()" class="btn btn-warning btn-sm">
            <i class="bi bi-printer" aria-hidden="true">&nbsp;</i> {{'shared.imprimir' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div class="row col-12 print-signature">
      <hr style="border-bottom: 1px solid black; margin: 100px 0 0 0" />
      <p class="text-center">{{'candidato_inscricao_ficha.assinatura_candidato' | translate}}</p>
    </div>
  </div>
</section>
