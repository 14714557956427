<section *ngIf="!loading">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item"><a (click)="voltar()" title="concursos" class="link">{{'shared.concursos' | translate}}</a></li>
        <li class="breadcrumb-item active">{{'shared.concurso' | translate}} #{{ concursoAndamento?.id }}</li>
      </ol>
    </nav>
    <div
      class="bg-light border rounded-3 mb-3"
      style="margin-bottom: 20px"
      itemscope=""
      itemtype="http://schema.org/Event">
      <div class="p-3">
        <div itemprop="location" itemscope="" itemtype="http://schema.org/Place">
          <p itemprop="name" style="font-size: 1rem">{{ concursoAndamento?.campus }}</p>
          <div style="display: none" itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
            <span itemprop="addressRegion">SP</span>
          </div>
        </div>
        <h2 itemprop="name" style="font-size: 1rem" class="text-primary">{{ concursoAndamento?.titulo }}</h2>
        <h3 style="font-size: 1rem">{{ concursoAndamento?.descricao }}</h3>
        <h4
          itemprop="startDate"
          attr.content="{{ concursoAndamento?.inicio }}T00:00"
          style="font-size: 1rem; font-weight: bold">
          {{'concurso.inscricoes' | translate}} {{ concursoAndamento?.inicio | date : 'dd/MM/yyyy' }} {{concursoAndamento?.horaInicio?.substring(0, 5)}} h {{'shared.ate' | translate}}
          {{ concursoAndamento?.fim | date : 'dd/MM/yyyy' }} {{'shared.as' | translate}} {{ concursoAndamento?.horaFim?.substring(0, 5) }} h
        </h4>
        <p *ngIf="concursoAndamento?.emailContato !== ''" style="font-size: 1rem">
          <span class="text-primary"><b>{{'concurso.email_contato' | translate}}:</b> {{ concursoAndamento?.emailContato }}</span>
        </p>
        <p *ngIf="concursoAndamento?.telefoneContato !== ''" style="font-size: 1rem">
          <span class="text-primary"><b>{{'concurso.telefone_contato' | translate}}:</b> {{ concursoAndamento?.telefoneContato }}</span>
        </p>
        <p *ngIf="concursoAndamento?.valorInscricao !== 0" style="font-size: 1rem">
          <b>{{'concurso.valor_inscricao' | translate}}:&nbsp;</b>R$ {{ concursoAndamento?.valorInscricao?.toFixed(2) }}
        </p>
        <p *ngIf="concursoAndamento?.vagas !== null && concursoAndamento?.vagas !== 0" style="font-size: 1rem">
          <b>{{'concurso.numero_vagas' | translate}}:&nbsp;</b>{{ concursoAndamento?.vagas }}
        </p>
        <p *ngIf="concursoAndamento?.destaque" style="font-size: 1rem" class="text-danger">
          <b>{{'concurso.destaque' | translate}}:&nbsp;</b>{{ concursoAndamento?.destaque }}
        </p>
        <p *ngIf="concursoAndamento?.resumo" style="font-size: 1rem">
          <b>Abstract:&nbsp;</b><br />{{ concursoAndamento?.resumo }}
        </p>
        <div class="row" style="margin: 6px 0">
          <div class="col-6" style="padding-left: 0">
            <a *ngIf="concursoAndamento?.edital" (click)="edital()" class="btn btn-warning btn-sm"
              ><i class="bi bi-book" aria-hidden="true">&nbsp;</i>{{'concurso.abrir_edital' | translate}}</a
            >
          </div>
          <div class="col-6 d-flex flex-row-reverse" style="padding-right: 0"></div>
        </div>
        <app-andamento
          [andamentos]="concursoAndamento?.andamentos"
          [id]="concursoAndamento?.id"
          [idConcurso]="0"
          [collapsed]="true"></app-andamento>
      </div>
    </div>
    <div class="row" style="margin-top: 10px">
      <div class="col-6">
        <a (click)="voltar()" class="btn btn-secondary btn-sm"
          ><i class="bi bi-arrow-left-short" aria-hidden="true">&nbsp;</i> <span>{{'shared.voltar' | translate}}</span></a
        >
      </div>
      <div class="col-6 d-flex flex-row-reverse">
        <a
          *ngIf="concursoAndamento?.periodoInscricao"
          routerLink="/concurso/{{ concursoAndamento?.id }}/inscricao"
          class="btn btn-primary btn-sm"
          ><i class="bi bi-check-circle" aria-hidden="true">&nbsp;</i><span>{{'concurso.fazer_inscricao' | translate}}</span></a
        >
      </div>
    </div>
  </div>
</section>
