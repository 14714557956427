/*
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2024 UNESP Universidade Estadual Paulista "Júlio de Mesquita Filho"
 *
 */

import { Component, OnInit } from '@angular/core'
import { Inscricao } from 'src/app/models/inscricao'
import { InscricaoService } from 'src/app/services/inscricao.service'
import { PaginationBaseComponent } from '../../pagination/components/pagination-base.component'

@Component({
  selector: 'app-candidato-inscricoes',
  templateUrl: './candidato-inscricoes.component.html',
  styleUrls: ['./candidato-inscricoes.component.css'],
})
export class CandidatoInscricoesComponent extends PaginationBaseComponent implements OnInit {
  inscricoes: Inscricao[] = []
  input: string = ''
  private _search: string = ''
  private _searchClicked: boolean = true

  constructor(private inscricaoService: InscricaoService) {
    super()
  }

  ngOnInit(): void {
    this.getData()
  }

  search(): void {
    this._search = this.input.trim().toLowerCase()
    this._searchClicked = true
    this.getData()
  }

  override getData(): void {
    let filter: any =
      this._search === ''
        ? { page: this.current - 1, size: this.perPage }
        : this._searchClicked
        ? { search: this._search }
        : { page: this.current - 1, size: this.perPage, search: this._search }
    this._searchClicked = false
    this.inscricaoService.listar(filter).subscribe(data => {
      this.inscricoes = data['content']
      this.totalPages = data['totalPages']
      this.totalElements = data['totalElements']
    })
  }

  isTipoDocente(tipo: string): boolean {
    return !(tipo == 'TECADM' || tipo == 'LD' || tipo == 'PESQ4' || tipo == 'PESQ3')
  }

  showSegundaFase(inscricao: Inscricao): boolean {
    if (inscricao.inicioFase2 === null || inscricao.fimFase2 === null) {
      return false
    } else {
      let inicio = Date.parse(inscricao.inicioFase2)
      let agora = Date.now()
      return inicio < agora
    }
  }
}
